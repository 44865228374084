import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/routes').then((m) => m.routes)
      },
      {
        path:'custDashboard',
        loadChildren:()=> import('./views/forms/customer/dashboard/routes').then((m)=>m.routes)
      },
      {
        path:'hotelDashboard',
        loadChildren:()=> import('./views/forms/hotel/hotel-dashboard/routes').then((m)=>m.routes)
      },
      {
        path:'activityDashboard',
        loadChildren:()=> import('./views/forms/tour/dashboard/routes').then((m)=>m.routes)
      },
      {
        path:'vehicleDashboard',
        loadChildren:()=> import('./views/forms/vehicle/dashboard/routes').then((m)=>m.routes)
      },
      {
        path:'ferryDashboard',
        loadChildren:()=> import('./views/forms/ferry/dashboard/routes').then((m)=>m.routes)
      },
      {
        path:'coordDashboard',
        loadChildren:()=> import('./views/forms/coordinator/dashboard/routes').then((m)=>m.routes)
      },
      {
        path:'cvDetails',
        loadChildren:()=> import('./views/forms/CVAll/cv-main/routes').then((m)=>m.routes)
      },
      {
        path:'cvPrint',
        loadChildren:()=> import('./views/forms/CVAll/cv-print/routes').then((m)=>m.routes)
      },       
      {
        path:'CustEdit',
        loadChildren:()=> import('./views/forms/customer/routes').then((m)=>m.routes)
      },
      {
        path:'profileView',
        loadChildren:()=>import('./views/forms/profile-page/routes').then((m)=>m.routes)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/routes').then((m) => m.routes)
      },
      {
        path: 'base',
        loadChildren: () => import('./views/base/routes').then((m) => m.routes)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/routes').then((m) => m.routes)
      },
      {
        path: 'forms',
        loadChildren: () => import('./views/forms/routes').then((m) => m.routes)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/routes').then((m) => m.routes)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/routes').then((m) => m.routes)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/routes').then((m) => m.routes)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/charts/routes').then((m) => m.routes)
      },
      {
        path: 'pages',
        loadChildren: () => import('./views/pages/routes').then((m) => m.routes)
      }
    ]
  },
  {
    path: '404',
    loadComponent: () => import('./views/pages/page404/page404.component').then(m => m.Page404Component),
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '401',
    loadComponent: () => import('./views/pages/page401/page401.component').then(m => m.Page401Component),
    data: {
      title: 'Page 401'
    }
  },
  {
    path: '500',
    loadComponent: () => import('./views/pages/page500/page500.component').then(m => m.Page500Component),
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    loadComponent: () => import('./views/pages/login/login.component').then(m => m.LoginComponent),
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    loadComponent: () => import('./views/pages/register/register.component').then(m => m.RegisterComponent),
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'passResetLink',
    loadComponent: () => import('./views/pages/forgot-pass/send-link/send-link.component').then(m => m.SendLinkComponent),
    data: {
      title: 'Reset Password'
    }
  },
  {
    path: 'forgotPassword',
    loadComponent: () => import('./views/pages/forgot-pass/forgot-pass.component').then(m => m.ForgotPassComponent),
    data: {
      title: 'Forgot Password'
    }
  },
  { path: '**', redirectTo: 'dashboard' }
];
