import { HttpInterceptorFn } from '@angular/common/http';

export const interceptorAuthInterceptor: HttpInterceptorFn = (req, next) => {
  const JWT_TOKEN = getJWT();
  // alert(JWT_TOKEN);

  if(JWT_TOKEN){
    var clonedRequest = req.clone({
      setHeaders:{
        Authorization:`Bearer ${JWT_TOKEN}`
      },
    });

    return next(clonedRequest);
  }
  return next(req);
};


function getJWT():string|null{
  return localStorage.getItem('ACAPP_JWT_TOKEN');
}