import { Injectable } from '@angular/core';
import { CoordUserCreate } from '../../../shared/Models/userMgmt/coordUserCreate';
import { LoginDto } from '../../../shared/Models/userMgmt/login';
import { LoginResponse } from '../../../shared/Models/userMgmt/loginResponse';
import { PagesRoleWise } from '../../../shared/Models/userMgmt/PagesRoleWise';
import { SaveEditResponse } from '../../../shared/Models/Common/saveEditResponse';
import { CommonService } from './common.service';
import { BehaviorSubject, Observable,map } from 'rxjs';
import { UserDetailsForgotPassword } from 'src/app/shared/Models/User/forgotPassUserDetails';
import { UserProfile } from 'src/app/shared/Models/User/userProfile';
import { ForgotPassword } from 'src/app/shared/Models/userMgmt/forgotPass';

@Injectable({
  providedIn: 'root'
})
export class UserMgmtService {

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

  private readonly JWT_TOKEN:string = 'ACAPP_JWT_TOKEN';
  private readonly USER_ID:string = 'ACAPP_USERID';
  private readonly INITIAL_NAME:string = 'ACAPP_INITIAL_NAME';
  private readonly USER_TYPE:string = 'ACAPP_USER_TYPE';
  private readonly FNAME:string = 'ACAPP_FNAME';
  private readonly LNAME:string = 'ACAPP_LNAME';
  private isValidToken:boolean = false;
  pageList!:PagesRoleWise[];

  constructor(private http: CommonService) { }

  createCoordUser
  (
    userId:string,
    userType:string,
    password:string
  ){
    let respose = new SaveEditResponse();
    
    respose.saveStatus = false;
    respose.savemessage = 'Save failed!';

    let user = new CoordUserCreate();
    user.password = password;
    user.userId = userId;
    user.userType = userType;
    return this.http.postUm(user,'Usermanagement');
  }

  login
  (
    email:string,
    password:string
  ):Observable<LoginResponse>{
    let loginDto = new LoginDto();
    loginDto.email = email;
    loginDto.password = password;
    return this.http.postUm(loginDto,'Usermanagement/Login');
  }

  doLoginUser(loginRes:LoginResponse){
    this.storeJwt(loginRes);
    this.isAuthenticatedSubject.next(loginRes.loginSuccess);
  }

  private storeJwt(loginRes:LoginResponse){
    localStorage.setItem(this.JWT_TOKEN,loginRes.token);
    localStorage.setItem(this.USER_ID,loginRes.userId);
    localStorage.setItem(this.INITIAL_NAME,loginRes.initialName);
    localStorage.setItem(this.USER_TYPE,loginRes.userType);
    localStorage.setItem(this.FNAME,loginRes.firstName);
    localStorage.setItem(this.LNAME,loginRes.lastName);
  }

  removeJwt(){
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.USER_ID);
    localStorage.removeItem(this.INITIAL_NAME);
    localStorage.removeItem(this.USER_TYPE);
    localStorage.removeItem(this.FNAME);
    localStorage.removeItem(this.LNAME);
    this.isAuthenticatedSubject.next(false);
  }
  
  isLoggedInUser():Promise<boolean>{
    let token = localStorage.getItem(this.JWT_TOKEN);
    return this.http.validateToken(token!);
  }

  sendPasswordResetMail(emailId:string):Observable<boolean>{
    return this.http.getUm('Usermanagement/SendPasswordResetMail?Emaild='+emailId)
    .pipe(map(responseData=>{
      return responseData;
    }));
  }

  getUserDetailsForgotPassPage(uid:string):Observable<UserDetailsForgotPassword>{
    return this.http.getUm('Usermanagement/GetUserDetails?UserId='+uid)
    .pipe(map(responseData=>{
      return responseData;
    }));
  }

  getPageList():Observable<PagesRoleWise[]>{
    return this.http.getAllUm('Usermanagement/GetPagesRoleWise?RoleName=asc')
    .pipe(map(responseData=>{
      this.pageList = responseData;
      return this.pageList;
    }));
  }

  forgotPass(userId:string,token:string,newPass:string){
    let respose = new SaveEditResponse();
    
    respose.saveStatus = false;
    respose.savemessage = 'Save failed!';

    let user = new ForgotPassword();
    user.email = 's@s.com';
    user.password = newPass;
    user.resetPasswordToken = token;
    user.userId = userId;
      return this.http.postUm(user,'Usermanagement/ChangePassword');
  }

  getProfile(userId:string):Observable<UserProfile>{
    return this.http.getUm('Usermanagement/GetUserDetails?UserId='+userId)
    .pipe(map(responseData=>{
      return responseData;
    }));
  }

}