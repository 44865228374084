import { INavData } from '@coreui/angular';

export const navItemsAdmin: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    iconComponent: { name: 'cil-home' }
  },
  {
    name: 'Customer',
    url: '/custDashboard',
    iconComponent: { name: 'cil-people' }
  },
  {
    name: 'Itinerary',
    url: '/activityDashboard',
    iconComponent: { name: 'cil-list' }
  },
  {
    name: 'Hotel',
    url: '/hotelDashboard',
    iconComponent: { name: 'cil-building' }
  },
  {
    name: 'Vehicles',
    url: '/vehicleDashboard',
    iconComponent: { name: 'cil-car-alt' }
  },
  {
    name: 'Ferry',
    url: '/ferryDashboard',
    iconComponent: { name: 'cil-boat-alt' }
  },  
  {
    name: 'Coordinator',
    url: '/coordDashboard',
    iconComponent: { name: 'cil-user' }
  },
  {
    name: 'Masters',
    url: '/forms',
    iconComponent: { name: 'cil-list-rich' },
    children: [
      {
        name: 'Itineraries',
        url: '/forms/activity',
        iconComponent: { name: 'cil-list' },
      },
      {
        name: 'Hotels',
        url: '/forms/hotel',
        iconComponent: { name: 'cil-building' },
      },
      {
        name: 'Vehicles',
        url: '/forms/vehicleMaster',
        iconComponent: { name: 'cil-car-alt' },
      },
      {
        name: 'Ferries',
        url: '/forms/ferry',
        iconComponent: { name: 'cil-boat-alt' },
      },
      {
        name: 'Coordinators',
        url: '/forms/coordinator',
        iconComponent: { name: 'cil-user' },
      },
      {
        name: 'Locations',
        url: '/forms/location',
        iconComponent: { name: 'cil-location-pin' },
      },
      {
        name: 'Agencies',
        url: '/forms/agency',
        iconComponent: { name: 'cil-people' },
      }
    ]
  }
];

export const navItemsCoord: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    iconComponent: { name: 'cil-home' }
  },
  {
    name: 'Customer',
    url: '/custDashboard',
    iconComponent: { name: 'cil-people' }
  },
  {
    name: 'Itinerary',
    url: '/activityDashboard',
    iconComponent: { name: 'cil-list' }
  },
  {
    name: 'Hotel',
    url: '/hotelDashboard',
    iconComponent: { name: 'cil-building' }
  },
  {
    name: 'Vehicles',
    url: '/vehicleDashboard',
    iconComponent: { name: 'cil-car-alt' }
  },
  {
    name: 'Ferry',
    url: '/ferryDashboard',
    iconComponent: { name: 'cil-boat-alt' }
  },  
  {
    name: 'Coordinator',
    url: '/coordDashboard',
    iconComponent: { name: 'cil-user' }
  }
];
