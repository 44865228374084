import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';

import { IconDirective,IconModule, IconSetService } from '@coreui/icons-angular';
import{cilBuilding,cilHome,cilCarAlt,cilBoatAlt,cilUserPlus,cilLocationPin,cilList,cilPeople,cilUser,cilBell,cilBellExclamation,cilHamburgerMenu,cilMenu, cilPen, cilSettings, cilPlus, cilArrowLeft, cilTrash,cilTriangle, cilBank,cilArrowThickRight, cilArrowThickLeft, cilFilter, cilArrowTop, cilArrowBottom, cilSearch, cilCaretTop, cilCaretBottom, cilSortAscending, cilSortDescending, cilCloudUpload, cilCloudDownload, cilArrowRight,cilPrint,cilLockLocked, cilMobile, cilAccountLogout, cilTask, cilX,cilListRich,cilEnvelopeClosed,cilCheckAlt,cilHistory} from '@coreui/icons';
import {
  ContainerComponent,
  INavData,
  ShadowOnScrollDirective,
  SidebarBrandComponent,
  SidebarComponent,
  SidebarFooterComponent,
  SidebarHeaderComponent,
  SidebarNavComponent,
  SidebarToggleDirective,
  SidebarTogglerDirective
} from '@coreui/angular';

import { DefaultFooterComponent, DefaultHeaderComponent } from './';
import { navItemsAdmin,navItemsCoord } from './_nav';
import { UserMgmtService } from 'src/app/views/forms/services/user-mgmt.service';
import { PagesRoleWise } from 'src/app/shared/Models/userMgmt/PagesRoleWise';

function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  standalone: true,
  imports: [
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarBrandComponent,
    RouterLink,
    IconDirective,
    NgScrollbar,
    SidebarNavComponent,
    SidebarFooterComponent,
    SidebarToggleDirective,
    SidebarTogglerDirective,
    DefaultHeaderComponent,
    ShadowOnScrollDirective,
    ContainerComponent,
    RouterOutlet,
    DefaultFooterComponent,
    IconModule
  ],
  providers:[
    IconSetService
  ]
})
export class DefaultLayoutComponent {
  // public navItems = navItems;
  public navItems!:INavData[];
  public navItem!:INavData;
  private pageList!:PagesRoleWise[];

  constructor(public iconSet:IconSetService,private service:UserMgmtService){
    iconSet.icons = {cilBuilding,cilHome,cilCarAlt,cilBoatAlt,cilUserPlus,cilLocationPin,cilList,cilPeople,cilUser,cilBell,cilBellExclamation,cilHamburgerMenu,cilMenu,cilPen,cilSettings,cilPlus,cilArrowLeft,cilTrash,cilTriangle,cilBank,cilArrowThickRight,cilArrowThickLeft,cilFilter,cilArrowTop,cilArrowBottom,cilSearch,cilCaretTop,cilCaretBottom,cilSortAscending,cilSortDescending,cilCloudUpload,cilCloudDownload,cilArrowRight,cilPrint,cilLockLocked,cilMobile,cilAccountLogout,cilTask,cilX,cilListRich,cilEnvelopeClosed,cilCheckAlt,cilHistory}

    if(localStorage.getItem('ACAPP_USER_TYPE')==='Admin'){
      this.navItems = navItemsAdmin;
    }
    else{
      this.navItems = navItemsCoord;
    }

    // this.service.getPageList()
    //                   .subscribe({
    //                     next:(data)=>{
    //                       this.pageList = data;
    //                       this.createMenuNavItems();
    //                     },
    //                     error:(err)=>{
    //                       this.pageList = [];
    //                     }
    //                   });

  }

  createMenuNavItems():void{
    this.navItems = [];
    this.pageList.forEach((item)=>{
      switch(item.pageName){
        case 'Page_Dashboard':
          this.navItem = {name:'Dashboard',url:'/dashboard',iconComponent: { name: 'cil-home' }}
          break;
        case 'Page_CustDashboard':
          this.navItem = {name:'Customer',url:'/forms/customer',iconComponent: { name: 'cil-people' }}
        break;
        //   case 'Page_HotelDashboard':
        // break;
        //   case 'Page_HotelMaster':
        //   break;
        //   case 'Page_IteneraryDashboard':
        //   break;
        //   case 'Page_IteneraryMaster':
        //   break;
        //   case 'Page_VehicleDashboard':
        //   break;
        //   case 'Page_VehicleMaster':
        //   break;
        //   case 'Page_FerryDashboard':
        //   break;
        //   case 'Page_FerryMaster':
        //   break;
        //   case 'Page_CoordDashboard':
        //   break;
        //   case 'Page_CoordMaster':
        //   break;
          case 'Page_LocationMaster':
            this.navItem = {name:'Location',url:'/forms/location',iconComponent: { name: 'cil-location-pin' }}
          break;
          case 'Page_AgencyMaster':
            this.navItem = {name:'Agency',url:'/forms/agency',iconComponent: { name: 'cil-bank' }}
          break;
      }
      this.navItems.push(this.navItem);
    });
  }

  onScrollbarUpdate($event: any) {
    // if ($event.verticalUsed) {
    // console.log('verticalUsed', $event.verticalUsed);
    // }
  }
}
