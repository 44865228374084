import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { Status } from '../../../shared/Models/Common/status';
import { LocationSelect } from '../../../shared/Models/Common/locationDropdown';
import { environment } from '../../../../environments/environment';
import {TokenRequest} from '../../../shared/Models/userMgmt/ValidateTokenReq';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private status!:Status[];
  private locations!:LocationSelect[];
  
  private commonAPIBaseUrl!:string;
  private masterAPIBaseUrl!:string;
  private transAPIBaseUrl!:string;
  private umAPIBaseUrl!:string;

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private httpOptionsUploadpdf = {
    headers: new HttpHeaders({ 'Content-Type': 'application/pdf' })
  };

  constructor(private http: HttpClient) { 
    this.commonAPIBaseUrl = environment.commonAPIBaseUrl;
    this.masterAPIBaseUrl = environment.masterAPIBaseUrl;
    this.transAPIBaseUrl = environment.transAPIBaseUrl;
    this.umAPIBaseUrl = environment.umAPIBaseUrl;
  }

  getActiveInactiveStatus():Observable<Status[]>{
    return this.getAllCommon('Common/Status')
        .pipe(map(resposeData=>{
          this.status = [];
          resposeData.forEach((item)=>{
            if(item.displayName=='Active'||item.displayName=='Inactive'){
              this.status.push(item);
            }
          });
          return this.status;
        }));
  }

  getBookedNotBookedStatus():Observable<Status[]>{
    return this.getAllCommon('Common/Status')
        .pipe(map(resposeData=>{
          this.status = [];
          resposeData.forEach((item)=>{
            if(item.name=='Booked'||item.name=='Open'){
              this.status.push(item);
            }
          });
          return this.status;
        }));
  }

  getLocationSelectData():Observable<LocationSelect[]>{
    return this.getAll('Location/GetLocationsDD')
        .pipe(map(resposeData=>{
          this.locations = resposeData;
          if(this.locations!=null && this.locations.length>0){
            this.locations.forEach((item)=>{
              item.locationId = item.locationId+'|'+item.lastUpdatedDate;
            });
          }
          return this.locations;
        }));
  }

  getAllCommon(apiUrl:string):Observable<any[]>{
    return this.http.get<any[]>(this.commonAPIBaseUrl + apiUrl);
  }

  getCommon(apiUrl:string):Observable<any>{
    return this.http.get<any>(this.commonAPIBaseUrl + apiUrl);
  }

  getAll(apiUrl:string):Observable<any[]>{
    return this.http.get<any[]>(this.masterAPIBaseUrl + apiUrl);
  }

  get(apiUrl:string):Observable<any>{
    return this.http.get<any>(this.masterAPIBaseUrl + apiUrl);
  }

  post(post: any,apiUrl:string): Observable<any> {
    return this.http.post<any>(this.masterAPIBaseUrl + apiUrl,post, this.httpOptions);
  }

  getAllTrans(apiUrl:string):Observable<any[]>{
    return this.http.get<any[]>(this.transAPIBaseUrl + apiUrl);
  }

  getTrans(apiUrl:string):Observable<any>{
    return this.http.get<any>(this.transAPIBaseUrl + apiUrl);
  }

  postTrans(post: any,apiUrl:string): Observable<any> {
    return this.http.post<any>(this.transAPIBaseUrl + apiUrl,post, this.httpOptions);
  }

  uploadFile(post: any,apiUrl:string): Observable<any> {
    return this.http.post<any>(this.transAPIBaseUrl + apiUrl,post, {reportProgress: true, observe: 'events'});
  }

  downloadFile(apiUrl:string): Observable<any> {
    return this.http.get(this.transAPIBaseUrl + apiUrl, {responseType: 'blob'});
  }

  getAllUm(apiUrl:string):Observable<any[]>{
    return this.http.get<any[]>(this.umAPIBaseUrl + apiUrl);
  }

  getUm(apiUrl:string):Observable<any>{
    return this.http.get<any>(this.umAPIBaseUrl + apiUrl);
  }

  postUm(post: any,apiUrl:string): Observable<any> {
    return this.http.post<any>(this.umAPIBaseUrl + apiUrl,post, this.httpOptions);
  }

  getPageAccess(pageName:string){
    return getAccess(pageName);
  }

  validateToken(token:string):Promise<boolean>{
    return tokenValidation(token);
  }
}


async function getAccess(pageName:string) {
  const data = await getPageAccess(pageName);
  return data;
}

async function getPageAccess(pageName:string):Promise<string>{
  const headers=new Headers();
  headers.set('Authorization','Bearer '+getJWT());

  const request:RequestInfo = new Request(environment.umAPIBaseUrl + 'Usermanagement/GetPageAccess?PageName='+pageName,{
    method:'GET',
    headers:headers
  });

  const response = await fetch(request);
  const data = await response.text();
  return data;      
}

function getJWT():string|null{
  return localStorage.getItem('ACAPP_JWT_TOKEN');
}

async function tokenValidation(token:string):Promise<boolean> {
  const data = await validateToken(token);
  return data;
}

async function validateToken(token:string):Promise<boolean>{
  let validateTokenReq = new TokenRequest();
  validateTokenReq.token = token;

  const headers=new Headers();
  headers.set('content-type','application/json');

  const request:RequestInfo = new Request(environment.umAPIBaseUrl + 'Usermanagement/ValidateToken',{
    method:'POST',
    headers:headers,
    body: JSON.stringify({
      token: token,
    })
  });

  const response = await fetch(request);
  const data = await response.text();
  return data==='true';
}